import React from 'react';
import { useEffect, useState, useRef } from 'react'
import axios from "axios";
import Chart from 'react-apexcharts';
import ReactApexChart from "react-apexcharts";
import PDFExport from './PDFExport.js';
const { AxiosURL, redirectURL } = require('../Config/GlobalVariables.js');

const axiosInstance = axios.create({
  baseURL: AxiosURL
});

function HumidityGraph(){

  const token = sessionStorage.getItem('bearerToken');
  if (!token){
    window.location.replace(redirectURL.toString());
  }
    // const headers = {
    //   Authorization: `Bearer ${token}`,
    // };
  
    // axiosInstance.post('/meteoData/VerifyToken', null, { headers })
    //   .then((res) => {
    //     if ( res.data.msg !== "Authentication successfully")
    //     window.location.replace(redirectURL.toString());
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   });

  
    const [meteoData, setMeteoData] = useState([]);
    const [ssoptions, setssoptions] = useState([]);

    useEffect(() => {
        getMeteoData();
        getssoptions();
    }, []);

    const selectRef = useRef(null);

    const getssoptions = async () => {

      const userData = await axiosInstance.post('/meteoData/getUser', { token });
      const userId = userData.data.id;
      const userProducts = await axios.get(`https://smartagricultureapi.horiafetti.ro/api/getProductsForUser?linkuser=${userId}`);

      const selectedValue = selectRef.current.value;

      //console.log(selectRef.current.value);
      const serialNumberOptions = userProducts.data;
      setssoptions(serialNumberOptions);
  }

    const getMeteoData = async () => {
      const userData = await axiosInstance.post('/meteoData/getUser', { token });
      const userId = userData.data.id;
      var serial_number = selectRef.current.value;
          const response = await axios.get(`https://smartagricultureapi.horiafetti.ro/api/getHumidityForGraph?serial_number=${serial_number}`);
          
        setMeteoData(response.data);
    }
    const arrayColumn = (arr, n) => arr.map(x => x[n]);

    
    
    const series = [
      {
        name: "Senzorul de umiditate 1", 
        data: arrayColumn(meteoData,'humidity1')
      },
      {
        name: "Senzorul de umiditate 1", 
        data: arrayColumn(meteoData,'humidity2')
      },
      {
        name: "Senzorul de umiditate 1", 
        data: arrayColumn(meteoData,'humidity3')
      }
    ];
    const options = {
      chart: {
        id: "simple-bar"
      },
      xaxis: {
        type: 'datetime',
        categories: arrayColumn(meteoData,'created_at')
      }
    };
    return (
      <div>
        <br></br>
        <center>
        <select ref={selectRef} class="select" id="SserialNumber" onChange={() => {getMeteoData(); getssoptions() }}>
            <option  value={'default'}>{"Select product"}</option>
                {
                    ssoptions.map((singleOption, index) => (
                        <option  value={singleOption.serial_number}>{singleOption.serial_number}</option>
                    ))
                }
            
            </select>
            </center>
        <br></br><br></br>
        <center>
          <div className='Chart'>
          <Chart options={options} type="line" series={series} />
          </div>
          </center>
        <br></br><br></br>
        <center><PDFExport pdfData={JSON.stringify(meteoData)}  type={"humidity"}/></center>
      </div>
    );
}

export default HumidityGraph;