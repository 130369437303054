import React from 'react';
import { useEffect, useState, useRef } from 'react'
import axios from "axios";
import Chart from 'react-apexcharts';
import ReactApexChart from "react-apexcharts";
import PDFExport from './PDFExport.js';
import html2canvas from "html2canvas";
const { AxiosURL, redirectURL } = require('../Config/GlobalVariables.js');

const axiosInstance = axios.create({
  baseURL: AxiosURL
});

function TemperatureGraph(){

  const token = sessionStorage.getItem('bearerToken');

if (!token){
  window.location.replace(redirectURL.toString());
}

    const [meteoData, setMeteoData] = useState([]);
    const [graphImage, setGraphImage] = useState([]);
    const [pdfText, setPdfText] = useState([]);
    const [ssoptions, setssoptions] = useState([]);

    useEffect(() => {
        getMeteoData();
        imageSelector();
        getPdfText();
        getssoptions();
    }, []);

    const selectRef = useRef(null);

    const getssoptions = async () => {

      const userData = await axiosInstance.post('/meteoData/getUser', { token });
      const userId = userData.data.id;
      const userProducts = await axios.get(`https://smartagricultureapi.horiafetti.ro/api/getProductsForUser?linkuser=${userId}`);

      const selectedValue = selectRef.current.value;

     // console.log(selectRef.current.value);
      const serialNumberOptions = userProducts.data;
      setssoptions(serialNumberOptions);
  }

    const getMeteoData = async () => {
        const userData = await axiosInstance.post('/meteoData/getUser', { token });
        const userId = userData.data.id;
        var serial_number = selectRef.current.value;
            const response = await axios.get(`https://smartagricultureapi.horiafetti.ro/api/getTemperatureForGraph?serial_number=${serial_number}`);
            
        setMeteoData(response.data);
    }
    const arrayColumn = (arr, n) => arr.map(x => x[n]);
    
    const handleImageDownload = async () => {
      const element = document.getElementById('apexchartssimple-bar'),
      canvas = await html2canvas(element),
      data = canvas.toDataURL('image/jpg'),
      link = document.createElement('a');
   
      console.log(data);
      link.href = data;
      link.download = 'downloaded-image.jpg';
   
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    };

    const imageSelector = async() =>{
      const imageCode1 = await html2canvas(document.querySelector("#print1"));
      const imageCode = imageCode1.toDataURL("image/png")
      setGraphImage(imageCode);

    } 

    const getPdfText = async() =>{

      setPdfText('aaaaaa' + "<b>kujgfjhgsdvb</b>");
    }
//console.log(meteoData);
    const series = [
      {
        name: "Senzorul de temperatura 1",
        data: arrayColumn(meteoData,'temperature1')
      },
      {
        name: "Senzorul de temperatura 2",
        data: arrayColumn(meteoData,'temperature2')
      },
      {
        name: "Senzorul de temperatura 3",
        data: arrayColumn(meteoData,'temperature3')
      }
    ];
    const options = {
      chart: {
        id: "simple-bar"
      },
      xaxis: {
        type: 'datetime',
        categories: arrayColumn(meteoData,'created_at')
      }
    };
    

    return (
      <div id="print1">
        <br></br>
        <center>
        <select ref={selectRef} class="select" id="SserialNumber" onChange={() => {getMeteoData(); getssoptions() }}>
            <option  value={'default'}>{"Select product"}</option>
                {
                    ssoptions.map((singleOption, index) => (
                        <option  value={singleOption.serial_number}>{singleOption.serial_number}</option>
                    ))
                }
            
            </select>
            </center>
        <br></br><br></br>
        <center>
          <div className='Chart'>
          <Chart options={options} type="line" series={series} />
          </div>
          </center>
        <br></br><br></br>
        <center><PDFExport pdfData={JSON.stringify(meteoData)} type={"temperature"}/></center>
 
        </div>
   
    );
}

export default TemperatureGraph;