import React, { useState, useEffect} from 'react';
import axios from 'axios';
import {Routes, Route, useNavigate} from 'react-router-dom';
import "./Login.css"
const { AxiosURL, redirectURL } = require('../Config/GlobalVariables.js');


const axiosInstance = axios.create({
  baseURL: AxiosURL
});

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [authenticated, setAuthenticated] = useState(false);
  
  const axiosInstance = axios.create({
    baseURL: AxiosURL, 
  });

  //const redirectURL = 'https://smartagriculture.horiafetti.ro';
 

   
  // useEffect(() => {
  //   // Verificați statutul autentificării la încărcarea componentei
  //   axiosInstance.post('/meteoData/VerifyToken')
  // .then((res) => {
  //   setAuthenticated(res.data.authenticated);
  // });
  // }, []);

  const handleLogin = () => {
   // console.log(username);
   // console.log(password);
    axiosInstance.post('/meteoData/Login', { username, password })
      .then((res) => {
       // console.log(res.data.token)
        if (res.data.token) {
          // Extrageți token-ul din răspunsul de la /login
          const token = res.data.token;
          sessionStorage.setItem('bearerToken', token);

          const headers = {
            Authorization: `Bearer ${token}`,
          };
  
          // axiosInstance.post('/VerifyToken', null, { headers })
          //   .then((res) => {
          //     setAuthenticated(res.data.authenticated);
          //   })
          //   .catch((error) => {
          //     console.error(error);
          //   });

  window.location.replace( redirectURL.toString() + '/meteo/home');
        } 
      })
      .catch((error) => {
        //console.error(error);
        window.location.replace(redirectURL.toString());
      });
  };


  const handleGuestLogin = () => {
    const username = 'guest';
    const password = 'guest';
    axiosInstance.post('/meteoData/Login', { username, password })
      .then((res) => {
        //console.log(res.data.token)
        if (res.data.token) {
          const token = res.data.token;
          sessionStorage.setItem('bearerToken', token);

          const headers = {
            Authorization: `Bearer ${token}`,
          };
  
  window.location.replace( redirectURL.toString() + '/meteo/home');
        } 
      })
      .catch((error) => {
        //console.error(error);
      });
  };


  const handleLogout = () => {
    axiosInstance.get('/logout')
      .then((res) => {
        if (res.data.success) {
          setAuthenticated(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className='container'>

          <form>
        <div className='input-container'>
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        
        <div className='button-container'>
    <center>
      <button type='button' onClick={handleLogin}>Login</button>
      <br></br>
      <button type='button' onClick={handleGuestLogin}>Guest Login</button>
    </center>
  </div>
  </form>

    </div>
  );
}

export default Login;