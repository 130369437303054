import React, { useState } from 'react';
import { BrowserRouter, Routes, Route, Link, Outlet } from "react-router-dom";
import HumidityGraph from "./Components/HumidityGraph.js";
import MeteoDataList from "./Components/MeteoDataList.js";
import TemperatureGraph from "./Components/TemperatureGraph.js";
import SoilMoistureGraph from "./Components/SoilMoistureGraph.js";
import ExportExcel from './Components/ExcelExport.js';
import Login from './Components/Login.js';
import Logout from './Components/Logout.js';


function App() {


  return (


    <BrowserRouter>

      <Routes>
        <Route path="/" element={<Login />} />
        {/* Utilizează o rută intermediară pentru a include Navbarul */}
        <Route
          path="/meteo"
          element={
            <>
            
                    <nav>
        <ul className="menu_list">
          <li><Link to="/meteo/home">Home</Link></li>
          <li><Link to="/meteo/temperaturegraph">TemperatureGraph</Link></li>
          <li><Link to="/meteo/humiditygraph">HumidityGraph</Link></li>
          <li><Link to="/meteo/soilmoisturegraph">SoilMoistureGraph</Link></li>
          <li><Link to="/meteo/logout">Logout</Link></li>
        </ul>
      </nav>

              <Outlet />
            </>
          }
        >
          <Route path="home" element={<MeteoDataList />} />
          <Route path="temperaturegraph" element={<TemperatureGraph />} />
          <Route path="humiditygraph" element={<HumidityGraph />} />
          <Route path="soilmoisturegraph" element={<SoilMoistureGraph />} />
          <Route path="logout" element={<Logout />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
