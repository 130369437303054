import { useRef } from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';


const PDFExport = ({pdfData, type}) => {

	const handleGeneratePdf = async () => {
		const doc = new jsPDF({
			format: 'a4',
			unit: 'px',
		});
		var json = JSON.parse(pdfData);

		doc.setFont('Inter-Regular', 'normal');
		doc.setFontSize(20);


		switch (type) {
			case 'temperature':
				console.log(json);
				doc.text("Evolutia temperaturii aerului", 160, 50);
				doc.setFontSize(12);
				doc.text("Ora si Data", 60, 100);
				doc.text("Temperatura aer zona 1", 130, 100);
				doc.text("Temperatura aer zona 2", 220, 100);
				doc.text("Temperatura aer zona 3", 310, 100);
				var i=0;
				for (i=0; i<=15; i++){
					var date = new Date(json[i]['created_at']);
					var dateFormat = date.getHours() + ":" + date.getMinutes() + ", "+ date.toDateString();
					console.log(dateFormat);
					doc.text("    " + String(dateFormat), 20, 120+15*i);
					doc.text("    " + String(json[i]['temperature1']), 150, 120+15*i);
					doc.text("    " + String(json[i]['temperature2']), 240, 120+15*i);
					doc.text("    " + String(json[i]['temperature3']), 330, 120+15*i);
				}
			  break;
			case 'humidity':
				console.log(json);
				doc.text("Evolutia umiditatii aerului", 160, 50);
				doc.setFontSize(12);
				doc.text("Ora si Data", 60, 100);
				doc.text("Umiditate aer zona 1", 130, 100);
				doc.text("Umiditate aer zona 2", 220, 100);
				doc.text("Umiditate aer zona 3", 310, 100);
				var i=0;
				for (i=0; i<=15; i++){
					var date = new Date(json[i]['created_at']);
					var dateFormat = date.getHours() + ":" + date.getMinutes() + ", "+ date.toDateString();
					console.log(dateFormat);
					doc.text("    " + String(dateFormat), 20, 120+15*i);
					doc.text("    " + String(json[i]['humidity1']), 150, 120+15*i);
					doc.text("    " + String(json[i]['humidity2']), 240, 120+15*i);
					doc.text("    " + String(json[i]['humidity3']), 330, 120+15*i);
				}
			  break;
			case 'moisturepercentage':
				console.log(json);
				doc.text("Evolutia umiditatii solului", 160, 50);
				doc.setFontSize(12);
				doc.text("Ora si Data", 60, 100);
				doc.text("Umiditate sol zona 1", 130, 100);
				doc.text("Umiditate sol zona 2", 220, 100);
				doc.text("Umiditate sol zona 3", 310, 100);
				var i=0;
				for (i=0; i<=15; i++){
					var date = new Date(json[i]['created_at']);
					var dateFormat = date.getHours() + ":" + date.getMinutes() + ", "+ date.toDateString();
					console.log(dateFormat);
					doc.text("    " + String(dateFormat), 20, 120+15*i);
					doc.text("    " + String(json[i]['moisturePercentage1']), 150, 120+15*i);
					doc.text("    " + String(json[i]['moisturePercentage2']), 240, 120+15*i);
					doc.text("    " + String(json[i]['moisturePercentage3']), 330, 120+15*i);
				}
			  break;
			default:
			  console.log(`Sorry, we are out`);
			}
			const filedate =  new Date().toLocaleDateString();
			const timedate = new Date().toLocaleTimeString();
		doc.save("date_" + filedate.toString() + "-time_" + timedate.toString() + ".pdf");    
	};

	return (
		<div>
			<button class="ButtonExcel" onClick={handleGeneratePdf}>
				Generate raport
			</button>
			
		</div>
	);
}

export default PDFExport;