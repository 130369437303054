import { useEffect, useState, useRef } from 'react'
import axios from "axios";
import { Link } from "react-router-dom";
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import ExportExcel from './ExcelExport';
import React from 'react';
import PDFExport from './PDFExport';
const { AxiosURL, redirectURL } = require('../Config/GlobalVariables.js');



const axiosInstance = axios.create({
  baseURL: AxiosURL
});

const axiosInstance1 = axios.create({
    baseURL: "https://smartagricultureapi.horiafetti.ro"
  });

const options = ["Last 10 values", "Last 25 values", "Last 50 values", "All values"];

const MeteoDataList = () => {

    const token = sessionStorage.getItem('bearerToken');
    if (!token){
        window.location.replace(redirectURL.toString());
      }

    const filedate =  new Date().toLocaleDateString();
			const timedate = new Date().toLocaleTimeString();
      const filename = "date_" + filedate.toString() + "-time_" + timedate.toString()

    const [meteoData, setMeteoData] = useState([]);
    const [ssoptions, setssoptions] = useState([]);
    const [deleteButton, setdeleteButton] = useState([]);
    useEffect(() => {
        getMeteoData();
        getssoptions();
       // getdeleteButton();
    }, []);

    const selectRef = useRef(null);

    const getssoptions = async () => {

        const userData = await axiosInstance.post('/meteoData/getUser', { token });
        const userId = userData.data.id;
        const userProducts = await axios.get(`https://smartagricultureapi.horiafetti.ro/api/getProductsForUser?linkuser=${userId}`);
        ///////////// button delete permission for guest
        if (userData.data.username == 'guest' && userData.data.id == 3)
        setdeleteButton(false);
////////////////////////////////////
        const selectedValue = selectRef.current.value;

        //console.log(selectRef.current.value);
        const serialNumberOptions = userProducts.data;
        setssoptions(serialNumberOptions);
    }

    const getMeteoData = async () => {
        var e = document.getElementById("catedinele");
        
        
        var value = e.value;
        var text = e.options[e.selectedIndex].text;
       
    // var serial_number = document.getElementById("SserialNumber");
    
        switch (text) {
        case 'Last 10 values': {
            //const response = await axios.get('https://smartagricultureapi.horiafetti.ro/api/parameters_data_10', { userName });
           // if (serial_number != 'default'){
            var serial_number = selectRef.current.value;
            const response = await axios.get(`https://smartagricultureapi.horiafetti.ro/api/parameters_data_10?serial_number=${serial_number}`);
            for (let x in response.data){

                var date = new Date(response.data[x]['created_at']);
                var dateFormat = date.getHours() + ":" + date.getMinutes() + ", "+ date.toDateString();
                response.data[x]['created_at'] = dateFormat;
            }

                //console.log(response.data);
            setMeteoData(response.data);
           // }

            break;
        }
        case 'Last 25 values': {
            //const response = await axios.get('http://localhost:3001/meteoData/getLast25MeteoDataStack');
            var serial_number = selectRef.current.value;
            const response = await axios.get(`https://smartagricultureapi.horiafetti.ro/api/parameters_data_25?serial_number=${serial_number}`);
            for (let x in response.data){
                //console.log(response.data[x]["created_at"]);
                var date = new Date(response.data[x]['created_at']);
                var dateFormat = date.getHours() + ":" + date.getMinutes() + ", "+ date.toDateString();
                response.data[x]['created_at'] = dateFormat;
            }

           // console.log(response.data);
            setMeteoData(response.data);
            break;
        }
        case 'Last 50 values': {
            var serial_number = selectRef.current.value;
            const response = await axios.get(`https://smartagricultureapi.horiafetti.ro/api/parameters_data_50?serial_number=${serial_number}`);
            for (let x in response.data){
                //console.log(response.data[x]["created_at"]);
                var date = new Date(response.data[x]['created_at']);
                var dateFormat = date.getHours() + ":" + date.getMinutes() + ", "+ date.toDateString();
                response.data[x]['created_at'] = dateFormat;
            }

            //console.log(response.data);
            setMeteoData(response.data);
            break;
        }
        case 'All values': {
            var serial_number = selectRef.current.value;
            const response = await axios.get(`https://smartagricultureapi.horiafetti.ro/api/parameters_data_all?serial_number=${serial_number}`);
            for (let x in response.data){
                //console.log(response.data[x]["created_at"]);
                var date = new Date(response.data[x]['created_at']);
                var dateFormat = date.getHours() + ":" + date.getMinutes() + ", "+ date.toDateString();
                response.data[x]['created_at'] = dateFormat;
            }

            //console.log(response.data);
            setMeteoData(response.data);
            break;
        }
        default: {
            var serial_number = selectRef.current.value;
            const response = await axios.get(`https://smartagricultureapi.horiafetti.ro/api/parameters_data_10?serial_number=${serial_number}`);
            for (let x in response.data){
                //console.log(response.data[x]["created_at"]);
                var date = new Date(response.data[x]['created_at']);
                var dateFormat = date.getHours() + ":" + date.getMinutes() + ", "+ date.toDateString();
                response.data[x]['created_at'] = dateFormat;
            }

            //console.log(response.data);
            setMeteoData(response.data);
            break;
        }

}

    }

    const deleteData = async (id) => {
        try {
          await axios.delete(`http://localhost:3001/meteoData/${id}`);
          //console.log(`http://localhost:3001/meteoData/${id}`)
          getMeteoData();
        } catch (error) {
          console.log(error);
        }
      };

    const ExcelExportData = meteoData;
    //console.log(ExcelExportData);

    return (
        <div><br></br><br></br>
                <div align="center">
                <select class="select" id="catedinele" onChange={() => getMeteoData()}>
                <option value="last10values">Last 10 values</option>
                <option value="last25values">Last 25 values</option>
                <option value="last50values">Last 50 values</option>
                <option value="allvalues">All registred values</option>
                
            </select>

            <select ref={selectRef} class="select" id="SserialNumber" onChange={() => {getMeteoData(); getssoptions() }}>
            <option  value={'default'}>{"Select product"}</option>
                {
                    ssoptions.map((singleOption, index) => (
                        <option  value={singleOption.serial_number}>{singleOption.serial_number}</option>
                    ))
                }
            
            </select>


            </div>
                <div className='ExcelButton'> <ExportExcel excelData={ExcelExportData} fileName={filename}/></div>

                <div class ='grid-container' >
                    { meteoData.map((meteoSingleData, index) => (
                        <div class="grid-item">
                             
                            Temperature: { ((meteoSingleData.temperature1+meteoSingleData.temperature1+meteoSingleData.temperature1)/3).toFixed(2) } <br></br>
                            Humidity: { ((meteoSingleData.humidity1+meteoSingleData.humidity2+meteoSingleData.humidity3)/3).toFixed(2) }<br></br>
                            Moisture Percentage: { ((meteoSingleData.moisturePercentage1+meteoSingleData.moisturePercentage1+meteoSingleData.moisturePercentage1)/3).toFixed(2) }<br></br>
                            Data inregistrarii: { meteoSingleData.created_at }
                            {/* {console.log(meteoSingleData.id)} */}
                            {deleteButton ? 
                            (<div><br></br><center><button class="delete_button" onClick={() => deleteData(meteoSingleData.id)}>Delete</button></center></div>) : null}
                        </div>
                    ))}
                   </div>     

        </div>

        
    )

}

export default MeteoDataList;