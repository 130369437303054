import React from 'react';
import axios from "axios";
const { AxiosURL, redirectURL } = require('../Config/GlobalVariables.js');

const axiosInstance = axios.create({
  baseURL: AxiosURL
});

function Logout(){

    sessionStorage.removeItem('bearerToken');
    window.location.replace(redirectURL);
    return (
      <div>
        <br></br><br></br><br></br><br></br><br></br>
        <center><h3>Logout ...</h3></center>
      </div>
    );
}

export default Logout;